

import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { computed,  defineComponent } from "vue";
import {useStore} from "vuex";
import router from "@/router";
import toastr from "toastr";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default defineComponent({
  name: "checkout-waiting",
  data(){
    return {
      payment_method: null,
    }
  },
  components: {
  },
  setup() {
    const store = useStore();
       const response =  {
              "success": true,
              "transaction": {
                "id": "tran_GPLDQODF2pIk45ZK",
                "transaction_type": "boleto",
                "gateway_id": "611266213",
                "amount": 17970,
                "status": "generated",
                "success": true,
                "url": "https://api.pagar.me/1/boletos/live_cl27wfyog6l8k0sm5l3dbmpc5",
                "pdf": "https://api.pagar.me/1/boletos/live_cl27wfyog6l8k0sm5l3dbmpc5?format=pdf",
                "line": "23791.22928 60010.311987 43000.046904 7 89640000017970",
                "barcode": "https://api.pagar.me/core/v5/transactions/tran_GPLDQODF2pIk45ZK/barcode",
                "qr_code": "https://api.pagar.me/core/v5/transactions/tran_GPLDQODF2pIk45ZK/qrcode",
                "nosso_numero": "611266213",
                "bank": "001",
                "document_number": "611266213",
                "instructions": "Não aceitar após o vencimento.",
                "due_at": "2022-04-23T23:59:59Z",
                "created_at": "2022-04-20T18:19:26Z",
                "updated_at": "2022-04-20T18:19:26Z",
                "gateway_response": {
                  "code": "200"
                },
                "antifraud_response": {},
                "metadata": {}
              }
            }

           
              /** 
              const transaction = {
                id: response.transaction.id,
                total_price: response.transaction.amount,
                payment_method: "boleto",
                payment_url: response.transaction.barcode,
                payment_download_url: response.transaction.pdf,
                payment_code: response.transaction.line,
                status: response.transaction.status,
              }
              **/ 
              const transaction = computed(() => {
                return store.getters.getActiveCheckout;
              });
           

    return {
      store,
      transaction
    };
  },
  created(){
    if(this.transaction.payment_method == 'pix'){
      setInterval(() => this.update(), 5000);
    }
  },
  methods: {
    async update() {
        await this.store.dispatch(Actions.GET_LATEST_ORDER_STATUS, {}).then(response => {
          const current_response = this.store.getters.getActiveCheckout;
          
          if(current_response.status == "paid" || current_response.status == "captured"){
            this.transaction.status = current_response.status;
              Swal.fire({
                      title: "Parabéns!",
                      text: "Bem vindo(a) ao club, você está pronto para começar a aprender!",
                      icon: "success",
                      confirmButtonText: "Ok"
                    }).then(() => {
                    
                      // redirect to home
                      router.push({name: 'dashboard'});
              });
            }
        }).catch(error => {
            console.log(error)
        })

        return;
    },
    async copyURL(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
      } catch($e) {
        console.log('Oops, esse tal de navigador clipboard não funciona no seu dispositivo :/');
      }
    },
    
    }
});
